import styled from "styled-components";

export const FooterLink = styled.a`
  display: block;
  text-decoration: underline;
  padding: 0 ${({ theme }) => theme.space.base};
  margin-bottom: ${({ theme }) => theme.space.base};
`;

export const FooterCookieButton = styled.button`
  outline: none;
  border: 0;
  padding: 0 ${({ theme }) => theme.space.base};
  margin-bottom: ${({ theme }) => theme.space.base};
  cursor: ${({ theme }) => theme.cursors.pointer};
  text-transform: uppercase;
  text-decoration: underline;
  & span {
    text-decoration: underline;
  }
`;
