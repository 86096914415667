import React, { useCallback } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Container, Flex } from "~/components/Layout";
import { Text } from "~/components/Text";
import { FooterData, OsanoWindow } from "./types";
import { FooterLink, FooterCookieButton } from "./styled";

const Footer: React.FC<FooterData> = ({ content, links }) => {
  const { t } = useTranslation();
  const contentLines = content?.split("\n");

  const openOsano = useCallback(() => {
    (window as OsanoWindow).Osano?.cm.showDrawer();
  }, []);

  return (
    <Container py="xl" as="footer" textAlign="center" color="utility.primary" position="relative">
      {contentLines?.map((line) => (
        <Text typeStyle="body-xs" key={`footer-content-line--${line.slice(0, 8)}`}>
          {line}
        </Text>
      ))}
      {!!links && (
        <Flex flexDirection={["column", "row"]} justifyContent="center" mt="base">
          {links.map(
            ({ href, title }, linkIndex) =>
              !!href &&
              !!title && (
                <FooterLink key={`footer-link--${title}-${linkIndex}`} href={href}>
                  <Text as="span" typeStyle="body-xs">
                    {title.toLocaleUpperCase()}
                  </Text>
                </FooterLink>
              ),
          )}
          <FooterCookieButton aria-label={t("cookie_preferences")} onClick={openOsano}>
            <Text as="span" typeStyle="body-xs">
              {t("cookie_policy")}
            </Text>
          </FooterCookieButton>
        </Flex>
      )}
    </Container>
  );
};

export default Footer;
