import React, { useCallback } from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { HomepageProps } from "~/types/homepage";
import { getChapterUrl } from "~/helpers/getChapterUrl";
import { Container, Box, Flex } from "~/components/Layout";
import { Text } from "~/components/Text";
import { headerPrimaryHeight } from "~/components/Header/constants";
import { BackgroundImage, ResponsiveImage } from "~/components/Image/index";
import styled from "styled-components";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import Footer from "~/components/Footer";
import { useParallax } from "~/helpers/hooks/useParallax";
import { useInView } from "react-hook-inview";
import RevealText from "~/components/RevealText";

const headerSafeHeight = `calc(100vh - ${headerPrimaryHeight}px)`;

const BrandImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: transform 0.5s ease;
`;

const HiddenBrandImage = styled.div`
  opacity: 0;
`;

const BrandLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12.5rem;

  z-index: 2;
`;

const BrandLink = styled(Link)`
  overflow: hidden;
  position: relative;
  display: block;
  margin-bottom: ${({ theme }) => theme.space["4xl"]};

  @media (hover: hover) {
    &:hover {
      & ${BrandImage} {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
`;

const DisabledBrandLink = styled.div`
  overflow: hidden;
  position: relative;
  display: block;
  margin-bottom: ${({ theme }) => theme.space["4xl"]};
  pointer-events: none;
  filter: grayscale(100%);
`;

const BackgroundImageContainer = styled.div`
  position: fixed;
  top: ${headerPrimaryHeight};
  left: 0;
  width: 100%;
  height: ${headerSafeHeight};
  transition: opacity 0.5s ease;
`;

export const Homepage: React.FC<HomepageProps> = ({ data: { page } }) => {
  const { t } = useTranslation();
  const [animateTextContent] = useInViewAnimation();
  const { background_image, brands, footer: [footer] = [] } = page;
  const [inViewRef, inView] = useInView();
  const parallaxRef = useParallax(inView, -0.1);

  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      inViewRef(node);
      parallaxRef(node);
    },
    [inViewRef, parallaxRef],
  );

  return (
    <Box position="relative" ref={setRefs}>
      <BackgroundImageContainer>
        <BackgroundImage image={background_image}>
          <Box width="100%" height={`calc(${headerSafeHeight} * 1.2)`} />
        </BackgroundImage>
      </BackgroundImageContainer>
      <Container position="relative" pb="8xl">
        <Flex
          maxWidth="36rem"
          color="primary"
          height={headerSafeHeight}
          flexDirection="column"
          justifyContent="center"
          ref={animateTextContent}
        >
          <Text opacity="0" as="div" typeStyle="body-sm" mb="4xl">
            {t("welcome_label")}
          </Text>
          <RevealText opacity="0" as="h1" typeStyle={["heading-3xl", "heading-4xl"]}>
            {t("welcome")}
          </RevealText>
        </Flex>
        <div>
          {brands.map(({ meta: { title }, slug, chapters, full_logo, cover_image }) => {
            const hasChapters = chapters && chapters.length > 0;
            const content = (
              <>
                <BrandLogo src={full_logo?.url} />
                <BrandImage>
                  <ResponsiveImage image={cover_image} />
                </BrandImage>
                <HiddenBrandImage>
                  <ResponsiveImage image={cover_image} />
                </HiddenBrandImage>
              </>
            );

            return hasChapters ? (
              <BrandLink key={title} to={getChapterUrl(slug, chapters?.[0]?.slug)}>
                {content}
              </BrandLink>
            ) : (
              <DisabledBrandLink key={title}>{content}</DisabledBrandLink>
            );
          })}
        </div>
      </Container>
      <Footer {...footer} />
    </Box>
  );
};

export const query = graphql`
  query($language: String!, $uid: String!) {
    ...MetaFragment
    ...HeaderFragment

    page: contentstackHomepage(publish_details: { locale: { eq: $language } }, uid: { eq: $uid }) {
      __typename
      label
      heading
      background_image {
        ...Image
      }
      footer {
        ...Footer
      }
      brands {
        meta {
          title
        }
        slug
        full_logo {
          url
        }
        cover_image {
          ...Image
        }
        chapters {
          uid
          slug
          meta {
            page_title
          }
        }
      }
    }
    # Mandatory query for i18next. All pages need this query.
    ...TranslationQueryFragment
  }
`;

export default Homepage;
